import React from 'react';
import { CssBaseline, LinearProgress } from '@material-ui/core';
import {
  createTheme,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import Pages from 'pages';

const queryClient = new QueryClient();

const theme = createTheme({
  palette: { primary: { main: '#000000' } },
  shape: {
    borderRadius: 0,
  },
});

export default function App() {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <React.Suspense fallback={<LinearProgress />}>
            <Router>
              <Pages />
            </Router>
          </React.Suspense>
          <ReactQueryDevtools initialIsOpen />
        </QueryClientProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}
