import React from 'react';
import { motion } from 'framer-motion';

const variants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
};

export default function FadeInOut({ children }) {
  return (
    <motion.div variants={variants} initial="hide" animate="show" exit="hide">
      {children}
    </motion.div>
  );
}
