import FadeInOut from 'components/FadeInOut';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Route, useLocation, Redirect, Switch } from 'react-router-dom';

const Queue = React.lazy(() => import('./Queue'));
const Customer = React.lazy(() => import('./Customer'));
const Home = React.lazy(() => import('./Home'));
const Signin = React.lazy(() => import('./Signin'));
const Store = React.lazy(() => import('./Store'));
const VirtualQueueBasic = React.lazy(() => import('./VirtualQueueBasic'));
const StoreBooking = React.lazy(() => import('./StoreBooking'));
const StoreStatus = React.lazy(() => import('./StoreStatus'));
const NotFound = React.lazy(() => import('./NotFound'));
const NotAllowed = React.lazy(() => import('./NotAllowed'));
const Admin = React.lazy(() => import('./Admin'));

export default function Pages() {
  const { pathname } = useLocation();

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Switch key={pathname}>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route path="/" exact>
          <FadeInOut>
            <Home />
          </FadeInOut>
        </Route>
        <Route path="/stores/:id/basic">
          <FadeInOut>
            <VirtualQueueBasic />
          </FadeInOut>
        </Route>
        <Route path="/stores/:id/booking">
          <FadeInOut>
            <StoreBooking />
          </FadeInOut>
        </Route>
        <Route path="/stores/:id/status">
          <FadeInOut>
            <StoreStatus />
          </FadeInOut>
        </Route>
        <Route path="/stores/:id">
          <FadeInOut>
            <Store />
          </FadeInOut>
        </Route>
        <Route path="/customers/:id/booked">
          <FadeInOut>
            <Customer booked />
          </FadeInOut>
        </Route>
        <Route path="/customers/:id">
          <FadeInOut>
            <Customer />
          </FadeInOut>
        </Route>
        <Route path="/queue/*">
          <FadeInOut>
            <Queue />
          </FadeInOut>
        </Route>
        <Route path="/signin">
          <FadeInOut>
            <Signin />
          </FadeInOut>
        </Route>
        <Route path="/not-allowed">
          <FadeInOut>
            <NotAllowed />
          </FadeInOut>
        </Route>
        <Route path="/admin">
          <FadeInOut>
            <Admin />
          </FadeInOut>
        </Route>
        <Route path="*">
          <FadeInOut>
            <NotFound />
          </FadeInOut>
        </Route>
      </Switch>
    </AnimatePresence>
  );
}
